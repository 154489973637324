(function ($) {
  Drupal.behaviors.contentBlock2Readmore = {
    attach: function (context, settings) {
      var $readmoreTrig = $('.js-readmore-trigger', context);
      var $readmoreCont = $('.js-readmore-container', context);
      var $readmoreCont = $('.readmore-label', context);

      // Read more expand collapse button event
      $readmoreTrig.on('click', function (e) {
        $readmoreCont.toggleClass('readmore-expanded');
        e.preventDefault;
      });
    }
  };
})(jQuery);
